@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "JalnanGothic";
  font-weight: bold;
  src: url("./fonts/JalnanGothic.otf") format("truetype");
}
@font-face {
  font-family: "JalnanGothic";
  font-weight: bold;
  src: url("./fonts/JalnanGothic.otf") format("truetype");
}
@font-face {
  font-family: 'GowunBatang-Regular';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2108@1.1/GowunBatang-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}